<!-- <link rel="stylesheet" href="../../../../style/commonElement.css"> -->
<template>
  <!-- 设备报警列表 -->
  <div class="newSeeds roadNetwork">
    <div class="title">
      <div class="title-top" style="margin-bottom: 2vh">
        <el-button type="primary" size="small" @click="add">智能控制设置</el-button>
        <!-- <el-button
          type="primary"
          size="small"
          @click="openorclose"
          >开启/关闭</el-button
        > -->
        <!-- <div class="el-title-item" style="display: inline-block">
          <el-form ref="form">
            <el-form-item label="节点编号">
              <el-input
                v-model="search"
                placeholder="请输入节点编号"
              
              ></el-input>
              <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            style="margin-left:1vw"
            @click="searching"
            >搜索</el-button
          >
            </el-form-item>
          </el-form>
        </div> -->
      </div>
      <div class="title-bottom">
        <div class="title-bottom-left">
          <!-- <el-button
            icon="el-icon-refresh-right"
            size="small"
            @click="searching"
            >刷新</el-button
          > -->
        </div>
        <!-- <div class="title-bottom-right">
          
          <el-button icon="el-icon-refresh" size="small" @click="reset"
            >重置</el-button
          >
        </div> -->
      </div>
    </div>
    <el-table v-loading="loading" ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
      <el-table-column label="序号" align="center" :index="indexMethod" width="100" type="index" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="名称" prop="name" show-overflow-tooltip align="center">
      </el-table-column>
      <el-table-column label="监测设备" prop="deviceName" show-overflow-tooltip align="center">
      </el-table-column>
      <el-table-column label="传感器" prop="sensorName" show-overflow-tooltip align="center">
      </el-table-column>

      <el-table-column label="条件" prop="exp" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.exp == 'gt'">大于</span>
          <span v-if="scope.row.exp == 'lt'">小于</span>
          <span v-if="scope.row.exp == 'ge'">大于等于</span>
          <span v-if="scope.row.exp == 'le'">小于等于</span>
          <span v-if="scope.row.exp == 'eq'">等于</span>
        </template>
      </el-table-column>
      <el-table-column label="阈值" prop="val" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="控制设备" prop="ctrlDeviceName" show-overflow-tooltip align="center">
      </el-table-column>
      <el-table-column label="开关" prop="ctrlSensorName" show-overflow-tooltip align="center">
      </el-table-column>
      <el-table-column label="指令" prop="ctrlState" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.ctrlState == '0'">关闭</span>
          <span v-if="scope.row.ctrlState == '1'">打开</span>
        </template>
      </el-table-column>
      <el-table-column label="建议措施" prop="remark" show-overflow-tooltip align="center">
        <!-- <template slot-scope="scope">
          <span>{{ scope.row.warnMsg ? scope.row.warnMsg : "暂无" }}</span>
        </template> -->
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button type="primary" size="small" icon="el-icon-edit" @click="edits(scope.row)"></el-button>
          <el-button type="primary" size="small" icon="el-icon-delete" @click="deletes(scope.row.id)"></el-button>
          <el-button type="primary" size="small" icon="el-icon-s-order" @click="detail(scope.row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination :page-sizes="[8, 10, 20, 50]" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal" @current-change="changePage"
        @size-change="changeSize">
      </el-pagination>
    </div>

    <!-- 编辑 /  新建-->
    <el-dialog title="" :visible.sync="editss" width="34vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ title }} </span>
        <span style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          ">
        </span>
      </div>
      <div class="addAlaForm">
        <el-form ref="propsData" :model="propsData" :rules="rules" label-width="10vw">
           <el-form-item label="名称" prop="name">
            <el-input v-model="propsData.name" :disabled="detailss"></el-input>
          </el-form-item>
          <el-form-item label="监测设备" prop="deviceNum">
            <!-- <el-input v-model="propsData.sensorCode" :disabled="detailss"></el-input> -->
            <el-select v-model="propsData.deviceNum" placeholder="请选择" @change="changeDevice" :disabled="detailss">
              <el-option :label="item.deviceName" :value="item.deviceNum" v-for="item in deviceList"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="传感器" prop="sensorNum">
            <el-select v-model="propsData.sensorNum" placeholder="请选择" :disabled="detailss" @change="changeSennor">
              <el-option v-for="item in newContent" :key="item.index" :label="item.name" :value="item.index">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="条件" prop="exp">
            <!-- <el-input v-model="propsData.sensorCode" :disabled="detailss"></el-input> -->
            <el-select v-model="propsData.exp" placeholder="请选择" :disabled="detailss">
              <el-option label="大于" value="gt"></el-option>
              <el-option label="小于" value="lt"></el-option>
              <el-option label="大于等于" value="ge"></el-option>
              <el-option label="小于等于" value="le"></el-option>
              <el-option label="等于" value="eq"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="阈值" v-show="!detailss" prop="val">
            <el-input type="number" v-model="propsData.val" :disabled="detailss"></el-input>
          </el-form-item>
          <el-form-item label="控制设备品牌" prop="brand">
            <el-select v-model="propsData.brand" placeholder="请选择" :disabled="detailss">
              <el-option v-for="item in ctrlNewContent" :key="item.val" :label="item.label" :value="item.val">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="控制设备" prop="ctrlDeviceNum">
            <el-select v-model="propsData.ctrlDeviceNum" placeholder="请选择" @change="changeCtrolDevice"
              :disabled="detailss">
              <el-option :label="item.deviceName" :value="item.deviceNum" v-for="item in ctrlDeviceList"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开关" prop="ctrlSensorNum" v-if="propsData.brand == '1'">
            <el-select v-model="propsData.ctrlSensorNum" placeholder="请选择" :disabled="detailss" @change="changeCtrlSennor">
              <el-option v-for="(item, index) in ctrlNewJDQ" :key="item.index" :label="item.name" :value="item.index">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开关状态" prop="ctrlState">
            <el-switch v-model="propsData.ctrlState" active-color="#13ce66" inactive-color="#ff4949" active-value="1"
            inactive-value="0">
            </el-switch>
          </el-form-item>
          <el-form-item label="控制时长"  prop="ctrlSensorDuration">
            <el-input type="number" v-model="propsData.ctrlSensorDuration" :disabled="detailss"></el-input>
          </el-form-item>
          <el-form-item label="建议措施" v-show="!detailss" prop="remark">
            <el-input v-model="propsData.remark" :disabled="detailss" type="textarea"></el-input>
          </el-form-item>

          <el-form-item size="large" v-show="showFlag" class="bigBtn">
            <el-button class="cancelBtn" type="info" @click="editss = false">取消</el-button>
            <el-button class="saveBtn" type="primary" @click="onsubmit('propsData')">确认</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <!-- 弹框 记录 -->
    <el-dialog title="" :visible.sync="recordss" width="50vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ title }} </span>
        <span style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          ">
        </span>
      </div>
      <div>
        <TableBox ref="tableBox" :addBtn='false' :tableParams="recordsParams" :tableHeader="tableRecordsHeader"
          request="/linkCtrlSetting/pageLog"></TableBox>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import TableBox from "@/components/lxw/TableBox/index.vue";
export default {
  components: { TableBox },  /*eslint-disable */
  data() {
    return {
      valueType: "1",
      clicks: true,
      tableData: [
        {
          orderNumber: "",
          logiStatus: "",
          deliveTime: "",
          invoiceNo: "",
          people: "",
        },
      ],
      recordss: false,
      tableRecordsHeader: [
        {
          label: "传感设备",
          key: "name",
        },
        {
          label: "传感节点",
          key: "deviceName",
        },
        {
          label: "控制设备",
          key: "ctrlDeviceName",
        },
        {
          label: "控制节点",
          key: "sensorName",
        },
        {
          label: "表达式",
          key: "expName",
          render: (h, params) => {
            if (params) {
              const { text, row } = params;
              let te = ''
              switch (row.exp) {
                case 'gt':
                  te = '大于';
                  break;
                case 'lt':
                  te = '小于';
                  break;
                case 'ge':
                  te = '大于等于';
                  break;
                case 'le':
                  te = '小于等于';
                  break;
                case 'eq':
                  te = '等于';
                  break;
                default:
                  break
              }
              return h("span", {}, te);
            }
          },
        },
        {
          label: "预警值",
          key: "val",
        },
        {
          label: "默认操作指令",
          key: "command",
          render: (h, params) => {
            if (params) {
              const { text, row } = params;
              let txt = row.command == '00' ? "关闭" : "开启";
              return h("span", {}, txt);
            }
          },
        },
        {
          label: "建议措施",
          key: "remark",
        },

      ],
      recordTableData: [],
      recordsParams: {
        deviceNum: "",
        sensorNum: "",
      },

      search: "",
      title: "",
      loading: false,
      editss: false,
      detailss: false,
      showFlag: false,
      pageSize: 10,
      pageNow: 1,
      dataTotal: 1,
      propsData: {
        name: "",
        deviceName: "",
        sensorName: "",
        exp: "",
        warnValue: "",
        warnSwitch: "",
        warnTimes: "",
        warnMail: "",
        warnTel: "",
        warnMsg: "",
        ctrlSensorDuration:"",
        ctrlState:"",
        ctrlSensorNum:""
      },
      level: "",
      rules: {
        deviceNum: [
          { required: true, message: "不可为空", trigger: "change" },
        ],
        sensorNum: [
          { required: true, message: "不可为空", trigger: "change" },
        ],
        ctrlDeviceNum: [
          { required: true, message: "不可为空", trigger: "change" },
        ],
        brand: [
          { required: true, message: "不可为空", trigger: "change" },
        ],
        exp: [{ required: true, message: "不可为空", trigger: "change" }],
        warnValue: [{ required: true, message: "不可为空", trigger: "change" }],
        warnTimes: [{ required: true, message: "不可为空", trigger: "change" }],
      },
      newFlag: false,
      options: [
        {
          value: "tel",
          label: "tel",
        },
        {
          value: "email",
          label: "email",
        },
      ],
      tips: "",
      flag: 0,
      flagWord: "open",
      deviceList: [],
      ctrlDeviceList: [],
      sennorList: [],
      temNameList: [],
      newContent: [],
      ctrlNewContent: [
        {
          label: '建大仁科',
          val: '1'
        },
        {
          label: '大友',
          val: '0'
        },
        {
          label: '精讯畅通',
          val: '2'
        },

      ],
      ctrlNewJDQ: []
    };
  },
  mounted() {
    this.getList();
    this.getDeviceList();
  },
  methods: {
    //传感器下拉选择
    changeSennor(val){
      this.propsData.sensorName=this.newContent[val].name;
    },
    //控制节点下拉选择
    changeCtrlSennor(val){
      this.propsData.ctrlSensorName=this.ctrlNewJDQ[val].name;
    },
    //  开关操作
    changeSwitch(row) {
   
      this.$postJSON("/warnManage/addOrUpdateWarnSetting", row).then((res) => {
        if (res.data.state == "success") {
          if (row.warnSwitch == 1) {
            this.$message.success("开启成功");
          } else {
            this.$message.success("关闭成功");
          }
          this.getList();
        } else {
          this.$message.error(res.data.msg);
          this.messageBox = false;
        }
      });

    },
    // 获取列表
    async getList() {
      // this.loading = true;
      const { data } = await this.$get("/linkCtrlSetting/page", {
        page: this.pageNow,
        size: this.pageSize,
        // name: this.search,
      });
      this.tableData = data.data;
      this.dataTotal = data.count;
      this.clicks = true;
    },
    // 序号
    indexMethod(index) {
      return index + this.pageSize * (this.pageNow - 1) + 1;
    },
    // 新建
    add() {
      this.clicks = true;
      let _this = this;
      setTimeout(() => {
        _this.$refs["propsData"].clearValidate();
      }, 30);
      this.title = "新增";
      this.editss = true;
      this.detailss = false;
      this.showFlag = true;
      this.newFlag = true;
      this.propsData = {};
    },
    // 详情
    details(row) {
      this.editss = true;
      this.showFlag = false;
      this.title = "触发器信息详情";

      this.detailss = true;
      this.propsData = JSON.parse(JSON.stringify(row));
      let deviceNumber = JSON.parse(JSON.stringify(row)).deviceNumber;

      this.getSennorList(deviceNumber);

      let expression = JSON.parse(JSON.stringify(row)).expression;

      if (expression.indexOf("<") != -1) {
        let expressionArr = expression.split("<");
        expression = expressionArr[1];
        this.valueType = "2";
        this.propsData.expression = expression;
      } else if (expression.indexOf(">") != -1) {
        let expressionArr = expression.split(">");
        expression = expressionArr[1];
        this.valueType = "1";
        this.propsData.expression = expression;
      }
    },
    // 编辑
    async edits(row) {
      let obj = { ...JSON.parse(JSON.stringify(row)) }
      // obj.deviceNum = parseFloat(obj.deviceNum)
      await this.changeDevice(obj.deviceNum);
      obj.sensorNum = parseFloat(obj.sensorNum)
      
      obj.ctrlSensorNum = parseFloat(obj.ctrlSensorNum)
      // obj.ctrlDeviceNum = parseFloat(obj.ctrlDeviceNum)
      if(obj.command == '255'){
        obj.ctrolState = true
        obj.brand = '0'
      }else if (obj.command.split('|').length == 3){        
          obj.brand = '1'
        let a = obj.command.split('|')
        obj.jdq = parseInt(a[0])
        if(a[1] == 1){
          obj.ctrolState = true
        }else{
           obj.ctrolState = false
        }
      }else{
         obj.ctrolState = false
        obj.brand = '0'
      }
      
      await this.changeCtrolDevice(obj.ctrlDeviceNum);
   
      this.propsData = obj;
      this.detailss = false;
      this.newFlag = false;
      this.showFlag = true;

      this.title = "编辑";
      this.editss = true;
    },
    // 控制记录
    detail(row) {
      this.showFlag = false;

      this.title = "控制记录";
      this.editss = false;
      this.recordss = true;
      this.propsData = JSON.parse(JSON.stringify(row));

      this.detailss = false;
      // this.newFlag = false;
    },
    // 新建 / 编辑 提交
    onsubmit(formName) {
      let { ctrolState, brand, ctrlSensorNum } = this.$refs[formName].model
      let command = ''
      if(brand == '1'){ // 

      }else{
        if(ctrolState){
          command = '255'
        }else{
          command = '00'
        }
      }
      this.propsData.ctrlSensorNum+=1;

      let params = {
        ...this.propsData,
        command,

      };
       //console.log(params, "----------")
      let url = "/linkCtrlSetting/save";
      if (this.formTitle == "新增") {
        delete params.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON(url, params).then((res) => {
            if (res.data.state == "success") {
              this.$message.success(res.data.msg);
              this.editss = false;
              this.getList();
            } else {
              this.$message.error(res.data.msg);
              this.messageBox = false;
            }
          });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },

    // 重置
    reset() {
      this.search = "";
      this.level = "";
      this.level1 = "";
      this.getList();
    },
    searching() {
      this.pageNow = 1;
      this.getList();
    },
    // 删除

    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/linkCtrlSetting/delete/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },
    async getDeviceList() {
      const { data } = await this.$get("/deviceManage/getDeviceInfos", {
        page: 1,
        size: 10000,
      });
      this.deviceList = data.datas.filter(item => item.deviceType != '2');
      this.ctrlDeviceList = data.datas.filter(item => item.deviceType == '2');
    },
    changeDevice(val) {
      let temId;
      let device = this.deviceList;
      for (let a = 0; a < device.length; a++) {
        if (device[a].deviceNum == val) {
          temId = device[a].deviceNum;
          this.propsData.deviceName=device[a].deviceName;

        }
      }
      this.$get(`/deviceManage/getDeviceSensor/${temId}`).then((res) => {
        let newData = res.data.data;
        let temContent = newData.templateContent.split("|");
        let temName = newData.sensorNameStr.split("|");
        this.newContent = []
        for (let i = 0; i < temContent.length; i++) {
          this.newContent.push({
            id: temContent[i],
            name: temName[i],
            index: i
          });
        }
      });

      // //console.log(this.temNameList);
    },

    changeCtrolDevice(val) {
      let temId;
      let device = this.ctrlDeviceList;
      //console.log(device,"控制设备")
      for (let a = 0; a < device.length; a++) {
        if (device[a].deviceNum == val) {
          temId = device[a].deviceNum;
          this.propsData.ctrlDeviceName=device[a].deviceName;
        }
      }
      let newTemList = [];
      this.$get(`/deviceManage/getDeviceSensor/${temId}`).then((res) => {
        let newData = res.data.data;
        let temContent = newData.templateContent.split("|");
        let temName = newData.sensorNameStr.split("|");
        this.ctrlNewJDQ = []
        for (let i = 0; i < temContent.length; i++) {
          this.ctrlNewJDQ.push({
            id: temContent[i],
            name: temName[i],
            index: i
          });
        }
      });

      // //console.log(this.temNameList);
    },


    // 更换每页数据
    changeSize(size) {
      this.pageSize = size;
      this.pageNow = 1;
      this.getList();
    },
    // 更换页码
    changePage(newpage) {
      this.pageNow = newpage;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "~@/style/backTable.less";
@import "~@/style/commonElement.css";
.newSeeds {
  padding: 4vh 2vw !important;
  box-sizing: border-box;
  height: 100%;
}

.newSeeds /deep/ .el-input__inner:hover {
  border-color: #07ca88;
}

.newSeeds /deep/ .el-input__inner:focus {
  border-color: #07ca88;
}

.roadNetwork /deep/ .el-table td.is-center,
.roadNetwork /deep/ .el-table th.is-center {
  text-align: left;
}

.roadNetwork /deep/ .el-table {
  max-height: 70vh;
  overflow-y: scroll;
}

.roadNetwork /deep/ .el-table th {
  background: #fafafa;
  box-shadow: 1px 1px 2px #fafafa;
}

.roadNetwork /deep/ .el-table th>.cell:nth-of-type(-n + 1) {
  border-right: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bigBtn /deep/.el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.title-bottom-right {
  display: flex;
  align-items: center;
}

/deep/.el-button {
  // height: 25px;
}

.title-bottom-right/deep/.el-button--primary {
  margin-left: 10px;
}

.radios {
  display: inline-block;
}
</style>